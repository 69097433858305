<template>
	<div data-id="searchbar">
		<form @submit.prevent="handleSubmit">
			<div class="relative">
				<div class="absolute inset-y-0 start-0 flex items-center pl-3 pointer-events-none">
					<svg class="w-4 h-4 text-dark" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
						viewBox="0 0 20 20">
						<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
							d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
					</svg>
				</div>
				<input id="default-search" autocomplete="off"
					:class="['w-full p-1 pl-10 bg-white focus:outline-none pr-[36px] leading-3', localSearchQuery && showResultSearch ? 'rounded-t-2xl' : 'rounded-2xl']"
					placeholder="Search products and companies" v-model="localSearchQuery" @input="onInput"
					@keydown="handleKeyDown" />
				<button v-if="localSearchQuery" type="button" @click="clearSearch" class="absolute inset-y-0 end-0 flex items-center pr-3">
					<svg class="w-4 h-4 text-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
			</div>
		</form>
		<transition name="fade">
			<div v-if="showResultSearch" class="absolute max-w-[calc(100%-32px)] bg-white shadow-lg w-[calc(100vw-32px)] z-[1] rounded-b-2xl">
				<hr v-if="searchResults.length" class="hr bg-light-blue opacity-[25%] w-[calc(100%-24px)] ml-[12px]" />
				<ul v-if="searchResults.length">
					<li v-for="(result, index) in searchResults" :key="result.id"
						:class="['px-3 py-2 pl-[40px] cursor-pointer', { 'active': index === focusedIndex, 'rounded-b-2xl': index === searchResults.length - 1 }]"
						@click="navigateToResult(result)">
						{{ result.title }}
					</li>
				</ul>
				<div v-else-if="localSearchQuery && notFoundMessage">
					<hr class="hr bg-light-blue opacity-[25%] w-[calc(100%-24px)] ml-[12px] mt-2" />
					<p class="px-3 py-2 pl-[40px] text-dark">{{ notFoundMessage }}</p>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
import { products } from '@/composables/useProducts'
import { companies } from '@/composables/useCompanies'

const props = defineProps({
	searchQuery: {
		type: String,
		default: ''
	},
	notFoundMessage: {
		type: String,
		default: null
	}
})

const emit = defineEmits(['update:query'])
const localSearchQuery = ref(props.searchQuery)
const searchResults = ref([])
const selectedQuery = ref(props.searchQuery)
const focusedIndex = ref(-1)


const showResultSearch = computed(() => selectedQuery.value !== localSearchQuery.value)

const onInput = (event: Event) => {
	localSearchQuery.value = (event.target as HTMLInputElement).value
	focusedIndex.value = -1
}

const clearSearch = () => {
	localSearchQuery.value = ''
	emit('update:query', '')
	searchResults.value = []
}

watch(localSearchQuery, (newQuery) => {
	if (newQuery) {
		const lowerCaseQuery = newQuery.toLowerCase()
		const productResults = products.filter(product =>
			product.title.toLowerCase().includes(lowerCaseQuery)
		)
		const companyResults = companies.filter(company =>
			company.title.toLowerCase().includes(lowerCaseQuery)
		)
		searchResults.value = [...productResults, ...companyResults]
	} else {
		searchResults.value = []
	}
})

const navigateToResult = result => {
	selectedQuery.value = result?.title 
	localSearchQuery.value = selectedQuery.value
	emit('update:query', result?.title)
}

const handleSubmit = () => {
	selectedQuery.value = localSearchQuery.value
	if (focusedIndex.value === -1) emit('update:query', localSearchQuery.value)
	else navigateToResult(searchResults.value[focusedIndex.value])
}

const handleKeyDown = (event: KeyboardEvent) => {
	if (event.key === 'ArrowDown') {
		event.preventDefault()
		if (focusedIndex.value < searchResults.value.length - 1) {
			focusedIndex.value++
		} else {
			focusedIndex.value = 0
		}
	} else if (event.key === 'ArrowUp') {
		event.preventDefault()
		if (focusedIndex.value > 0) {
			focusedIndex.value--
		} else {
			focusedIndex.value = searchResults.value.length - 1
		}
	} else if (event.key === 'Enter') {
		event.preventDefault()
		handleSubmit()
	}
}
</script>

<style lang="stylus" scoped>
.fade-enter-active,
.fade-leave-active 
	transition opacity 0.35s 

.fade-enter-from,
.fade-leave-to 
	opacity 0 

.active
	background-color #f7fafc

li:last-child.active
	border-radius 0 0 1rem 1rem
</style>
