import likeIcon from '@/assets/img/icons/like.svg'
import susgrainable from '@/assets/img/companies/susgrainable.svg'
import generic from '@/assets/img/companies/generic.png'

// For testing
import company1 from '@/assets/img/companies/placeholders/company-1.svg'
import company2 from '@/assets/img/companies/placeholders/company-2.svg'
import company3 from '@/assets/img/companies/placeholders/company-3.svg'
import company4 from '@/assets/img/companies/placeholders/company-4.svg'
import company5 from '@/assets/img/companies/placeholders/company-5.svg'

export default function useCompanies() {
	return {
		findAll: () => companies,
		findById: (id: string) => {
			const foundCompany = companies.find(company => company.id === id) || null
			return foundCompany
		},
		findByCategory: (category: string) => {
			return companies.filter(company => company.category === category)
		},
		search: (query: string) => {
			const lowerCaseQuery = query.toLowerCase()
			return companies.filter(company => 
				company.title.toLowerCase().includes(lowerCaseQuery)
			)
		}
	}
}

// Real data from client (need to add scores, likes, and company image)
export const companies = [
	{
		id: 'company-0',
		title: 'Susgrainable',
		subtitle: '1',
		category: 'Food products',
		image: susgrainable,
		dateAdded: '08/06/2021', 
		score: 88, 
		likes: 12, 
		icons: [likeIcon]
	},
		{
		id: 'company-1',
		title: 'Generic Textile',
		subtitle: '1',
		category: 'Apparel',
		image: generic, 
		dateAdded: '10/06/2021', 
		score: 88, 
		likes: 12, 
		icons: [likeIcon]
	},
]

// // Placeholder data (used for testing)
// export const companies = [
// 	{
// 		id: 'company-0',
// 		title: 'Susgrainable',
// 		subtitle: '22',
// 		category: 'Food products',
// 		image: company1,
// 		dateAdded: '10/06/2021',
// 		score: 81,
// 		likes: 15,
// 		icons: [likeIcon]
// 	},
// 	{
// 		id: 'company-1',
// 		title: 'Bakery Company A',
// 		subtitle: '36',
// 		category: 'Food products',
// 		image: company2,
// 		dateAdded: '02/06/2024',
// 		score: 92,
// 		likes: 9,
// 		icons: [likeIcon]
// 	},
// 	{
// 		id: 'company-2',
// 		title: 'Tea Company A',
// 		subtitle: '18',
// 		category: 'Food products',
// 		image: company3,
// 		dateAdded: '04/06/2024',
// 		score: 68,
// 		likes: 8,
// 		icons: [likeIcon]
// 	},
// 	{
// 		id: 'company-3',
// 		title: 'Candy Company',
// 		subtitle: '31',
// 		category: 'Food products',
// 		image: company5,
// 		dateAdded: '12/06/2022',
// 		score: 76,
// 		likes: 11,
// 		icons: [likeIcon]
// 	},
// 	{
// 		id: 'company-4',
// 		title: 'Apparel Company A',
// 		subtitle: '8',
// 		category: 'Apparel',
// 		image: company4,
// 		dateAdded: '10/07/2022',
// 		score: 65,	
// 		likes: 1,
// 		icons: [likeIcon]
// 	},
// 	{
// 		id: 'company-5',
// 		title: 'Apparel Company B',
// 		subtitle: '35',
// 		category: 'Apparel',
// 		image: company5,
// 		dateAdded: '07/02/2022',
// 		score: 95,	
// 		likes: 14,
// 		icons: [likeIcon]
// 	},
// ]