import cartIcon from '@/assets/img/icons/cart.svg'
import likeIcon from '@/assets/img/icons/like.svg'
import bakingMix from '@/assets/img/products/baking-mix.png'
import tshirt from '@/assets/img/products/tshirt.png'

// For testing
// import product1 from '@/assets/img/products/placeholders/product-1.svg'
// import product2 from '@/assets/img/products/placeholders/product-2.svg'
// import product3 from '@/assets/img/products/placeholders/product-3.svg'
// import product4 from '@/assets/img/products/placeholders/product-4.svg'
// import product5 from '@/assets/img/products/placeholders/product-5.svg'

export default function useProducts() {
	return {
		findAll: () => products,
		findById: (id: string) => {
			return products.find(product => product.id === id) || null
		},
		findByCategory: (category: string) => {
			return products.filter(product => product.category === category)
		},
		search: (query: string) => {
			const lowerCaseQuery = query.toLowerCase()
			return products.filter(product => 
				product.title.toLowerCase().includes(lowerCaseQuery)
			)
		}
	}
}

const nodes = [
	{
		meshName: 'Node_4_primitive',
		title: "Growth of barley",
		sections: [
			{
				title: "Primary Regions",
				content: "Alberta, Saskatchewan, and Manitoba are the main barley producers."
			},
			{
				title: "Economic Impact",
				content: "Vital to Canada's agricultural economy. Supports local economies and rural employment."
			},
			{
				title: "Research and Innovation",
				content: "Canada excels in developing pest-resistant, disease-resistant, and weather-resilient barley varieties, enhancing crop sustainability."
			}
		]
	},
	{
		meshName: 'Node_4.001_primitive',
		title: "Harvesting",
		sections: [
			{
				title: "Harvest Timing",
				content: "Barley is harvested in Canada from late July through August. <br>Proper timing ensures grains have ideal moisture content and quality."
			},
			{
				title: "Precision Agriculture",
				content: "Farmers use GPS and data analytics to optimize timing and efficiency, promoting sustainability."
			}
		]
	},
	{
		meshName: 'Node_4.002_primitive',
		title: "Malt House",
		sections: [
			{
				title: "Malting Process",
				content: "Involves steeping, germinating, and drying barley grains. <br>Water enables germination, then drying halts it at the right stage. <br>Enzymes develop during germination, breaking down starches for baking."
			},
		]
	},
	{
		meshName: 'Node_4.003_primitive',
		title: "Brewery",
		sections: [
			{
				title: "Brewing Process",
				content: "Barley malt is used to brew beer; grains are separated after brewing."
			},
			{
				title: "Brewer's Spent Grain (BSG)",
				content: "BSG is the byproduct left after brewing beer, rich in fiber and protein. <br>Susgrainable collects BSG for use in baking flour."
			},
			{
				title: "Sustainability",
				content: "Utilizing BSG reduces waste and supports a circular economy."
			},
		]
	},
	{
		meshName: 'Node_4.004_primitive',
		title: "Collection and processing",
		sections: [
			{
				title: "BSG Processing",
				content: "BSG is transported to a facility, dried, and milled. <br>Processing enhances nutritional profile: high protein, low gluten."
			},
			{
				title: "Quality Control",
				content: "Ensures flour meets safety, quality, and regulatory standards."
			},
		]
	},
	{
		meshName: 'Node_4.005_primitive',
		title: "Packaging - baking flour",
		sections: [
			{
				title: "Packaging",
				content: "Uses recycling packaging. <br>Packaging design optimizes protection and durability, ensuring safe transit from factory to consumer."
			},
		]
	},
].map((n, idx) => ({
	...n,
	id: idx + 1
}))

// Real data from client
export const products = [
	{
		id: 'product0',
		title: 'Baking Mix',
		subtitle: 'Susgrainable',
		category: 'Food products',
		image: bakingMix,
		score: 84,
		dateAdded: '10/15/2024',
		likes: 120,
		icons: [cartIcon, likeIcon],
		mapUrl: new URL('/assets/models/map2.glb', import.meta.url).href,
		mapNodes: nodes,
		facts: {
			sections: [
				{
					title: 'Ingredients',
					type: 'image',
					image: new URL('/assets/img/products/ingredients_susgrainable.svg', import.meta.url).href,
				},
				{
					title: 'Circularity and Sustainability Key Points',
					type: 'text',
					textContent: "Utilizing BSG to create baking flour is a key sustainability initiative. By repurposing the spent grain, SUSGRAINABLE reduces waste and supports a circular economy. This process also significantly lowers the environmental impact compared to traditional disposal methods.",
				},
			],
		},
	},
	{
		id: 'product1',
		title: 'T-shirt',
		subtitle: 'Generic Textile',
		category: 'Apparel',
		image: tshirt,
		score: 78,
		dateAdded: '08/11/2023',
		likes: 20,
		icons: [cartIcon, likeIcon],
		mapUrl: new URL('/assets/models/map1.glb', import.meta.url).href,
		mapNodes: [
			"Node_1_primitive",
			"Node_2_primitive",
			"Node_3_primitive",
			"Node_4_primitive",
			"Node_5_primitive",
			"Node_6_primitive",
			"Node_7_primitive",
			"Node_8_primitive"
		].map((n, idx) => (
			{
				id: idx + 1,
				meshName: n,
				title: 'Title node ' + (idx + 1),
				sections: [
					{
						title: "Section title",
						content: "Lorem ipsum odor amet, consectetuer adipiscing elit. Per torquent vehicula venenatis porta fringilla vivamus. Posuere quam elit morbi tristique, suscipit dui. Ante cubilia condimentum pharetra inceptos rutrum suspendisse ut tincidunt eleifend. Neque interdum scelerisque lobortis metus eleifend. Condimentum per sit lacus conubia; congue amet arcu magna?"
					},
				]
			}
		)),
		facts: {
			sections: [
				{
					title: 'Raw Materials',
					type: 'image',
					image: new URL('/assets/img/products/ingredients_tshirt.svg', import.meta.url).href,
				},
				{
					title: 'Circularity and Sustainability Key Points',
					type: 'text',
					textContent: "This ethically produced polyester and cotton blend shirt features recycled polyester and is designed for easy recycling or composting. It’s made with organic cotton, produced using water and energy-efficient methods, and adheres to fair labor standards throughout the supply chain.",
				},
			],
		},
	},
]


// // Placeholder data (for testing)
// export const products = [
// 	{
// 		id: 'product0',
// 		title: 'Baking Mix',
// 		subtitle: 'Susgrainable',
// 		category: 'Food products',
// 		image: product1,
// 		score: 84,
// 		dateAdded: '10/15/2024',
// 		likes: 120,
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product1',
// 		title: 'Upcycled Barley Flour',
// 		subtitle: 'Susgrainable',
// 		category: 'Food products',
// 		image: product2,
// 		score: 73,
// 		dateAdded: '12/06/2021',
// 		likes: 700,
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product2',
// 		title: 'Pine Nuts Hummus',
// 		subtitle: 'Bakery Company A',
// 		category: 'Food products',
// 		image: product3,
// 		score: 71,
// 		dateAdded: '12/06/2023',
// 		likes: 80,
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product3',
// 		title: 'Tea Box Set - Classic Flavours',
// 		subtitle: 'Tea Company A',
// 		category: 'Food products',
// 		image: product4,
// 		score: 69,
// 		dateAdded: '12/05/2024',
// 		likes: 0,
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product4',
// 		title: 'Watermelon Vegan Gummy Candy',
// 		subtitle: 'Candy Company',
// 		category: 'Food products',
// 		image: product5,
// 		score: 87,
// 		dateAdded: '10/06/2025',
// 		likes: 90,
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product5',
// 		title: 'Beet Hummus',
// 		subtitle: 'Bakery Company A',
// 		category: 'Food products',
// 		image: product3,
// 		score: 65,
// 		dateAdded: '12/08/2024',
// 		likes: 52,
// 		icons: [cartIcon, likeIcon]
// 	},
// 		{
// 		id: 'product6',
// 		title: 'Tea Box Set - Around the World',
// 		subtitle: 'Tea Company A',
// 		category: 'Food products',
// 		image: product4,
// 		score: 95,
// 		dateAdded: '11/03/2024',
// 		likes: 12,	
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product7',
// 		title: 'Mixed Fruit Gummy Candy',
// 		subtitle: 'Candy Company',
// 		category: 'Food products',
// 		image: product5,
// 		score: 64,
// 		dateAdded: '10/06/2024',
// 		likes: 55,
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product8',
// 		title: 'Cotton Shirt',
// 		subtitle: 'Apparel Company A',
// 		category: 'Apparel',
// 		image: product5,
// 		score: 84,
// 		dateAdded: '10/06/2024',
// 		likes: 60,
// 		icons: [cartIcon, likeIcon]
// 	},
// 		{
// 		id: 'product9',
// 		title: 'Cotton Pants',
// 		subtitle: 'Apparel Company B',
// 		category: 'Apparel',
// 		image: product1,
// 		score: 81,
// 		dateAdded: '10/08/2024',
// 		likes: 11,
// 		icons: [cartIcon, likeIcon]
// 	},
// 	{
// 		id: 'product10',
// 		title: 'Cotton Tie',
// 		subtitle: 'Apparel Company B',
// 		category: 'Apparel',
// 		image: product2,
// 		score: 84,
// 		dateAdded: '12/08/2024',
// 		likes: 177,
// 		icons: [cartIcon, likeIcon]
// 	},
// ]